import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PinDropIcon() {
    return (
        <SvgIcon viewBox="0 0 48 48" style={{ width: 48, height: 48 }}>
            <path
                d="M24 32.95C27.3 30.2833 29.7917 27.7167 31.475 25.25C33.1583 22.7833 34 20.4667 34 18.3C34 15.3 33.0667 12.8333 31.2 10.9C29.3333 8.96667 26.9333 8 24 8C21.0667 8 18.6667 8.96667 16.8 10.9C14.9333 12.8333 14 15.3 14 18.3C14 20.4667 14.8417 22.7833 16.525 25.25C18.2083 27.7167 20.7 30.2833 24 32.95ZM24 38C19.3 34.5333 15.7917 31.1667 13.475 27.9C11.1583 24.6333 10 21.4333 10 18.3C10 14.1333 11.3 10.7083 13.9 8.025C16.5 5.34167 19.8667 4 24 4C28.1333 4 31.5 5.34167 34.1 8.025C36.7 10.7083 38 14.1333 38 18.3C38 21.4333 36.8417 24.6333 34.525 27.9C32.2083 31.1667 28.7 34.5333 24 38ZM24 22C25.1 22 26.0417 21.6083 26.825 20.825C27.6083 20.0417 28 19.1 28 18C28 16.9 27.6083 15.9583 26.825 15.175C26.0417 14.3917 25.1 14 24 14C22.9 14 21.9583 14.3917 21.175 15.175C20.3917 15.9583 20 16.9 20 18C20 19.1 20.3917 20.0417 21.175 20.825C21.9583 21.6083 22.9 22 24 22ZM10 44V40H38V44H10Z"
                fill="#191919"
            />
        </SvgIcon>
    );
}
